function makeElement(
    tagName: string,
    attrs: { [attr: string]: string } = {},
    text: string | null,
    innerHTML: string | null
) {
    const el = document.createElement(tagName);

    for (const attr in attrs) {
        if (attr) {
            el.setAttribute(attr, attrs[attr]);
        }
    }

    if (text) {
        el.textContent = text;
    }

    if (innerHTML) {
        el.innerHTML = innerHTML;
    }

    return el;
}

export function lazyLoadLinks() {
    const lateLinkList = document.querySelectorAll('[data-lazy-link]') as NodeListOf<HTMLElement> | null;

    if (lateLinkList && lateLinkList.length > 0) {
        lateLinkList.forEach((lateLoadLink: HTMLElement) => {
            if (lateLoadLink.parentElement) {
                const linkAttrs: { [attr: string]: string } = {};

                Array.from(lateLoadLink.attributes).forEach(attribute => {
                    if (attribute.name === 'data-lazy-link') {
                        if (attribute.value !== 'null') {
                            linkAttrs.href = attribute.value;
                        }
                    } else {
                        linkAttrs[attribute.name] = attribute.value;
                    }
                });

                const newLinkEl = makeElement('a', linkAttrs, null, lateLoadLink.innerHTML);
                lateLoadLink.parentElement.replaceChild(newLinkEl, lateLoadLink);
            }
        });
    }
}
